<template>
  <div class="OrderSummary">
    <h2 class="o-default-h2 u-font-heading-light domain-title"> {{ $t('userDomains.title') }}</h2>
    <div class="summary-content">
      <section>
        <CartItemsReadOnly class="domain-list"/>
        <BillingDetailsSummary v-if="billingDetailsDefault" :billingDetailsDefault="billingDetailsDefault" />
        <BillingDetailsEmpty v-else />
      </section>
      <section>
        <CartSummary />
      </section>
    </div> <!-- /.summary-content -->
  </div> <!-- /.OrderSummary -->
</template>

<script>
import { mapGetters } from 'vuex';
import BillingDetailsSummary from '@/components/billing-details/BillingDetailsSummary';
import BillingDetailsEmpty from '@/components/billing-details/BillingDetailsEmpty';
import CartItemsReadOnly from '@/components/cart/CartItemsReadOnly';
import CartSummary from '@/components/cart/CartSummary';
import {
  FETCH_BILLING_DETAILS_ALL
} from '@/store/actions.type';

export default {
  name: 'OrderSummary',
  components: {
    BillingDetailsEmpty,
    BillingDetailsSummary,
    CartItemsReadOnly,
    CartSummary,
  },
  computed: {
    ...mapGetters([
      'billingDetailsDefault',
      'isBillingDetailsLoading'
    ])
  },
  created() {
    this.$store.dispatch(FETCH_BILLING_DETAILS_ALL)
  }
}
</script>

<style lang="scss" scoped>
.OrderSummary {

  .domain-title {
    text-align: center;
    @include desktop {
      text-align: left;
    }
  }

  .summary-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row;
    @include desktop {
      grid-template-columns: 3.2fr 1.6fr;
      gap: 3rem;
    }
  }

  .domain-list {
    margin-bottom: 1.6rem;
  }
}
</style>