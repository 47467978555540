<template>
  <div class="CartSummary">
    <div class="summary-layout md-elevation-2">
      <div class="card-title summary-layout-header">
        <h3 class="o-default-h3">{{ $t('cartItems.paymentSummary') }}</h3>
      </div>
      <section class="cart-subtotal">
        <div class="card-content u-strong">
          <span>{{ $t('cartItems.subTotal') }}</span>
          <span class="cart-prices u-strong">{{ $t('cartItems.euroSign') }}{{ this.totalAmountEuroNoVat.toFixed(2) }}</span>
        </div>
        <div class="card-content u-strong">
          <span>{{ $t('cartItems.taxes') }}</span>
          <span class="cart-prices u-strong">{{ $t('cartItems.euroSign') }}{{ this.totalTaxEuro.toFixed(2) }}</span>
        </div>
      </section>
      <hr>
      <section class="cart-total">
        <div class="card-content u-strong">
          <span class="cart-text-total">{{ $t('cartItems.total') }}</span>
          <span class="cart-price-total cart-prices">{{ $t('cartItems.euroSign') }}{{ this.totalAmountEuro.toFixed(2) }}</span>
        </div>
        <md-button @click="createPayment()" class="md-raised md-primary continue-btn">{{ $t('common.actions.pay') }}</md-button>
        <span v-if="checkoutErrors" class="u-red md-error">{{ $t('common.error.paymentFailed')}}</span>
        <div class="checkout-footer">
          <span class="material-icons lock-icon md-">lock_outline</span>
          <span class="secure-payment">{{ $t('common.securedBy') }}</span>
          <span><img class="payU-logo" src="@/assets/images/vendor-logos/payment/payU_logo.svg" alt="payU_logo" /></span>
        </div>
      </section>
    </div>
  </div> <!-- /.CartSummary -->
</template>

<script>
import { mapGetters } from 'vuex';
import { POST_CHECKOUT_ORDER } from '@/store/actions.type';
import { SET_CHECKOUT_ERROR } from '@/store/mutations.type';

export default {
  name: 'CartSummary',
  data() {
    return {
      items: [],
      totalPrice: 0,
      totalPriceNoVat: 0,
      vat: 0,
    };
  },
  computed: {
    ...mapGetters([
      'checkoutErrors',
      'checkoutPaymentForm',
      'totalAmountEuro',
      'totalAmountEuroNoVat',
      'totalAmountRonNoVat',
      'totalTaxEuro',
    ])
  },
  methods: {
    createPayment() {
      this.isLoading = true;
      this.$store.dispatch(POST_CHECKOUT_ORDER)
        .then(() => {
          const checkoutPaymentFormHtml = document.createRange().createContextualFragment(this.checkoutPaymentForm).firstElementChild;
          document.body.appendChild(checkoutPaymentFormHtml);

          const paymentFormSubmitBtn = checkoutPaymentFormHtml.querySelector('input[type="submit"]');
          paymentFormSubmitBtn.click();

          document.body.removeChild(checkoutPaymentFormHtml);
        })
        .catch((error) => {
          console.error('Error creating payment:', error);
          this.$store.commit(SET_CHECKOUT_ERROR, error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.CartSummary {

  .continue-btn .md-button-content {
    font-size: $type-scale-size-6;
  }
}
</style>

<style lang="scss" scoped>
.CartSummary {

  .continue-btn {
    display: block;
    margin: 2rem auto 1rem;
    min-height: 4.8rem;
    padding: 1rem 0;
    width: 95%;
  }

  .summary-layout {
    background-color: $white;
    font-weight: 500;
    margin-bottom: 1.6rem;
    min-width: 100%;
    padding: 1rem 0 0;
  }

  .card-title {
    font-size: 3rem;
    font-weight: 400;
    margin: 0 auto;
    text-align: left;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    padding: .8rem 2.4rem;
    text-align: left;
  }

  .checkout-footer {
    align-items: center;
    color: $palette-secondary-color-five;
    display: flex;
    font-family: $font-family-heading-light;
    font-size: 1.2rem;
    font-weight: 400;
    gap: .7rem;
    justify-content: center;
    margin: 0 auto;
    padding: .8rem;
  }

  .payU-logo {
    display: inline-block;
    height: 2.5rem;
    margin: 0 auto;
  }

  .lock-icon {
    color: $palette-secondary-color-five;
    font-size: 1.5rem;
  }

  .secure-payment {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .cart-total {
    margin: 1.2rem 0;
  }

  .cart-prices {
    float: right;
    font-weight: 500;
  }

  .cart-price-total {
    font-size: 2rem;
    font-weight: 600;
  }

  .cart-subtotal {
    min-width: 100%;
    margin: 1.2rem 0;
  }

  .cart-text-total {
    font-size: 2rem;
    font-weight: bold;
  }

  .summary-layout-header {
    padding: 1rem 2.4rem .8rem;
  }
}
</style>
