<template>
  <div class="CartSummaryView">
    <OrderSummary />
  </div> <!-- /.CartSummaryView -->
</template>

<script>
import OrderSummary from '@/components/OrderSummary';

export default {
  name: 'CartSummaryView',
  components: {
    OrderSummary
  },
};
</script>

<style lang="scss">
.CartSummaryView {
}
</style>
